<template>
  <div id="Login" class="gradiant-bg">
    <v-container fluid style="z-index: 999; position: relative">
      <v-row align="center" class="pa-5">
        <v-col cols="12" class="d-flex justify-center" v-if="signInMethod==='mobile'">
          <v-card width="500px" class="pa-5 elevation-0">
            <h1 class="font-weight-bold">Welcome,</h1>
            <h2 class="font-weight-regular grey--text mb-16">Login with Mobile Number</h2>
            <div>
              <v-text-field :rules="[rules.mobileLength,rules.mobileRequired]" @keyup.enter="signInMobile"
                            autofocus label="Registered Mobile Number*" mask="##### #####" outline ref="mobile" required
                            v-model="mobile"></v-text-field>
            </div>
            <v-btn :loading="loading" @click="signInMobile" class="primary py-6 elevation-0 mt-5" width="100%">LOGIN
            </v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" class="d-flex justify-center" v-if="signInMethod==='otp'">
          <v-card width="500px" class="pa-5 elevation-0">
            <h1 class="font-weight-bold">OTP verification,</h1>
            <h2 class="font-weight-regular grey--text mb-16">OTP send to Mobile +91 {{ mobile }}</h2>
            <div>
              <v-text-field :rules="[rules.otpLength]" @keyup.enter="verifyOtp" autofocus label="Enter OTP*"
                            mask="####" outline ref="otp" required v-model="otp"></v-text-field>
            </div>
            <v-btn :loading="loading" @click="verifyOtp" width="100%" class="primary py-6 elevation-0 mt-5">VERIFY
            </v-btn>
            <v-btn :disabled="timer!==0" @click="signInMobile" width="100%"
                   class="white text--primary elevation-0 mt-3">RESEND OTP
              {{ timer ? '(' + timer + ')' : '' }}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Snackbar :toaster="toaster"></Snackbar>
  </div>
</template>

<script>
import Snackbar from '../components/Common/Snackbar'
import Constants from '../Constants/Constants'
import NetworkCommunicator from '../plugins/NetworkResourceHandler'

export default {
  name: 'Login',
  components: { Snackbar },
  data () {
    return {
      loading: false, toaster: {}, constants: Constants,
      mobile: '', otp: '', mobileToken: '',
      timer: 30, timeOut: null, signInMethod: 'mobile',
      rules: {
        mobileRequired: value => !!value || 'Mobile Required.',
        mobileLength: value => value.length === 10 || 'Mobile Must be 10 digit',
        otpLength: value => value.length === 4 || 'OTP Must be 4 digit',
      },
    }
  },
  beforeCreate () {
    if (localStorage.getItem('token') != undefined && localStorage.getItem('token') != '') {
      this.$router.replace({ path: '/login' })
    }
  },
  methods: {
    async signInMobile () {
      let self = this
      if (this.mobile && this.mobile.length === 10) {
        self.loading = true
        let response = await NetworkCommunicator('POST', `${Constants.api_url}adminLogin`,
            { mobile: this.mobile })
        if (response.flag) {
          self.timer = 30
          if (self.timeOut)
            clearInterval(self.timeOut)
          self.timeOut = setInterval(function () {
            if (self.timer !== 0)
              self.timer = parseInt(self.timer) - 1
          }, 1000)
          self.mobileToken = response.data.id
          self.toaster = { enable: true, color: 'green', message: response.message }
          self.signInMethod = 'otp'
          setTimeout(function () {
            self.$refs.otp.resetValidation()
          }, 100)
          self.loading = false
        } else {
          self.toaster = { enable: true, color: 'red', message: response.message }
          self.loading = false
        }
      }
    },
    async verifyOtp () {
      let self = this
      self.loading = true
      let response = await NetworkCommunicator('POST', `${Constants.api_url}verifyOtpLogin`,
          { id: this.mobileToken, otp: parseInt(this.otp), deviceInfo: {}, deviceType: 'Web', fcmToken: '' })
      if (response.flag) {
        self.toaster = { enable: true, color: 'green', message: response.message }
        let permissions = []
        response.data.permissionObj.forEach(permission => {
          permissions.push({
            name: permission.menuObj.name, icon: permission.menuObj.icon, to: permission.menuObj.url,
            permissionMeta: permission.meta, menuMeta: permission.menuObj.meta,
          })
        })
        this.$store.dispatch('setPermissions', permissions)
        localStorage.setItem('permissionObj', JSON.stringify(permissions))
        localStorage.setItem('user', JSON.stringify(response.data.data))
        localStorage.setItem('token', response.data.token)
        self.toaster = { enable: true, color: 'green', message: 'Logged in successfully.' }
        self.loading = false
        setTimeout(function () {
          self.$router.push('/masters')
          self.$router.go(0)
        }, 50)
      } else {
        self.toaster = { enable: true, color: 'red', message: response.message }
        self.loading = false
      }
    },
  },
}
</script>

<style scoped>
.gradiant-bg {
  /*background-image: url("../assets/login-bg.png");*/
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.gradiant-bg:after {
  position: absolute;
  background-image: url("../assets/login-bg.png");
  opacity: 0.2;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  content: "";
  height: 100vh;
  width: 100%;
  bottom: -70px;
  z-index: 0;
}
</style>
